.w-100 {
width:100% !important;
}

.font-size-13px {
font-size:13px !important;
}

.m-0 {
margin:0px !important;
}

.pt-2 {
padding-top:2px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.letter-spacing-0 {
letter-spacing:0px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.p-0 {
padding:0px !important;
}

@media screen and (min-width: 992px) {.pr-lg-0 {
padding-right:0px !important;
}

}
 .font-size-13px {
font-size:13px !important;
}

.m-0 {
margin:0px !important;
}

.p-2 {
padding:2px !important;
}

.h-100 {
height:100% !important;
}

@media screen and (min-width: 576px) {.mt-sm-n100px {
margin-top:-100px !important;
}

}
 @media screen and (min-width: 768px) {.mt-md-n125px {
margin-top:-125px !important;
}

}
 @media screen and (min-width: 992px) {.mt-lg-n150px {
margin-top:-150px !important;
}

}
 .px-20px {
padding-left:20px !important;padding-right:20px !important;
}

@media screen and (min-width: 992px) {.px-lg-100px {
padding-left:100px !important;padding-right:100px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

@media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.w-100 {
width:100% !important;
}

.mx-n10px {
margin-left:n10px !important;margin-right:n10px !important;
}

@media screen and (min-width: 576px) {.px-sm-10vw {
padding-left:10vw !important;padding-right:10vw !important;
}

}
 @media screen and (min-width: 768px) {.px-md-20vw {
padding-left:20vw !important;padding-right:20vw !important;
}

}
 @media screen and (min-width: 992px) {.px-lg-0 {
padding-left:0px !important;padding-right:0px !important;
}

}
 .px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.w-100 {
width:100% !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.w-100 {
width:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.py-lg-160px {
padding-top:160px !important;padding-bottom:160px !important;
}

}
 .mb-45px {
margin-bottom:45px !important;
}

.font-size-40px {
font-size:40px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.h-100 {
height:100% !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.font-size-14 {
font-size:14px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.line-height-22px {
line-height:22px !important;
}

.mb-45px {
margin-bottom:45px !important;
}

@media screen and (min-width: 992px) {.mb-lg-65px {
margin-bottom:65px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

.px-35px {
padding-left:35px !important;padding-right:35px !important;
}

.font-size-15 {
font-size:15px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.mt-30px {
margin-top:30px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 768px) {.mr-md-20px {
margin-right:20px !important;
}

}
 .font-weight-400 {
font-weight:400 !important;
}

.h-100 {
height:100% !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mt-20px {
margin-top:20px !important;
}

.w-100 {
width:100% !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-45px {
margin-bottom:45px !important;
}

@media screen and (min-width: 992px) {.mb-lg-70px {
margin-bottom:70px !important;
}

}
 .line-height-32px {
line-height:32px !important;
}

.mt-20px {
margin-top:20px !important;
}

.pt-100px {
padding-top:100px !important;
}

.mb-n80px {
margin-bottom:-80px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 992px) {.pl-lg-80px {
padding-left:80px !important;
}

}
 @media screen and (min-width: 1200px) {.pl-xl-100px {
padding-left:100px !important;
}

}
 @media screen and (min-width: 992px) {.py-lg-70px {
padding-top:70px !important;padding-bottom:70px !important;
}

}
 .font-size-12px {
font-size:12px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.my-20px {
margin-top:20px !important;margin-bottom:20px !important;
}

.my-10px {
margin-top:10px !important;margin-bottom:10px !important;
}

.font-size-25px {
font-size:25px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-25px {
font-size:25px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.line-height-38px {
line-height:38px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 1200px) {.pt-xl-0 {
padding-top:0px !important;
}

}
 .pt-20px {
padding-top:20px !important;
}

.w-100 {
width:100% !important;
}

.pt-125px {
padding-top:125px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.pb-15px {
padding-bottom:15px !important;
}

.m-0 {
margin:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.font-size-13px {
font-size:13px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.font-size-18px {
font-size:18px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.opacity-25 {
opacity:25 !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mt-30px {
margin-top:30px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mr-5px {
margin-right:5px !important;
}

.mt-10px {
margin-top:10px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}


/*
This is where you write custom SASS
*/
a {
    &.arrow-link:hover {
        .fa-arrow-right {
            margin-left: 5px !important;

        }
    }
    &.arrow-link {
        .fa-arrow-right {
            transition: .3s ease;
        }
    }
    &.arrow-link:hover {
        .fa-arrow-circle-right {
            margin-left: 5px !important;

        }
    }
    &.arrow-link {
        .fa-arrow-circle-right {
            transition: .3s ease;
        }
    }
}
::placeholder {
    font-family: 'proxima-nova', sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    color: rgba($color: #15181C, $alpha: 0.62);
}

.form-control {
    font-family: 'proxima-nova', sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    color: rgba($color: #15181C, $alpha: 0.62);
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 615px;
        min-height: 245px;
        margin: 105px 0 0 0 !important;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }
    section.interior-contact-bar {
        &.True {
            padding-top: 300px !important;
            @media screen and (max-width: 1600px) {
                & {
                    padding-top: 600px !important;
                }
            }
            @media screen and (max-width: 991px) {
                & {
                    padding-top: 100px !important;
                }
            }
        }
        
        .contact-bar-wrap {
            background: linear-gradient(#34302a 0%, #29241c 100%);
            border: 15px solid #fff;
            border-right: none!important;
            padding: 100px 0px 100px 160px;
            @media screen and (max-width: 1600px) {
                & {
                    padding: 100px 0px 100px 100px;
                }
            }
            @media screen and (max-width: 991px) {
                & {
                    border: none !important;
                    padding: 100px 30px;
                }
            }
        }
        
    }
    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: linear-gradient(#0D0D0D 0%, #0D0D0D 100%);
            p.h3 {
                text-transform: uppercase;
                color: #fff;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                line-height: 30px;
            
            }
            label {
                display: none;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}
.owl-carousel .owl-next, .owl-carousel .owl-prev {
    right: 65px;
    left: auto;
}
.owl-carousel .owl-nav {
    right: auto;
    left: -368px;
}
section.testimonials {
    .title {
        position: relative;
        right: 0;
        top: 215px;
        font-size: 45px;
        line-height: 52px;
    }
    cite {
        :nth-child(2) {
            color: rgba(255, 255, 255, 0.57) !important;
        }
        :nth-child(3) {
            color: rgba(255, 255, 255, 0.57) !important;
        }
    }
}
.navbar {
    .mod3 {
        a.bg-primary {
            min-width: 330px;
            display: inline-block;
            float: right;
            height: 105px;
            padding: 38px 50px;
            letter-spacing: 0.12em;
            font-weight: 600;
            font-size: 14px;
            color: #fff;

            .fa-arrow-circle-right {
                font-size: 23px;
                position: relative;
                top: 2px;
                left: 5px;
            }
        }
    }
}
#desktopMenu.justify-content-center {
    justify-content: left !important;
}
.tagline-section {
    .border-md {
        border: 1px solid rgba(112,112,112,.25);
        height: 240px;
        margin: 0 70px 0 0;
    }
}
.main-content {
    h1 {
        color: #1B1F27;
        text-transform: none;
        letter-spacing: 0.01em;
    }
}
.testimonials-section {
    .owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
        display: block !important;
    }
    .quotes {
        right: 52%;
        top: 16%;
    }
}
section.contact-form {
    .at {
        right: 45.5%;
        top: 12.5%;
    }
    textarea.form-control {
        height: 142px;
    }
    .wrap {
        padding: 120px 100px;
        background-color: #D58F1F;
        width: 610px;

        .title {
            font-size: 25px;
            font-weight: 300;
            line-height: 35px;
        }
        .link {
            margin-bottom: 10px;
            font-weight: 18px;
            font-weight: 400;

            i {
                margin-right: 10px;
                font-size: 16px;
                color: rgba($color: #F6F6F4, $alpha: 0.64);

            }
        }
    }
    .btn {
        width: 148px !important;
        padding: 20px 15px;
    }
}
section.faqs-section i {
    margin: 2px 10px 0 0 !important;
}
section.faqs-section #accordion div svg {
    order: 1;
}
section.faqs-section #accordion div span:first-of-type {
    order: 2;
}
section.faqs-section {
    a.justify-content-between {
        justify-content: flex-start !important;
    }
}
section.faqs-section #accordion div a:not(.collapsed) i::before {
    font-family: "Font Awesome 5 Pro"; content: "\f068";
}
.main-int {
    h1 {
        color: #363d4b;
    }
}
.foot {
    .mod1 {
        .h3 {
            letter-spacing: -0.02em;
        }
        .h6 {
            font-size: 13px !important;
            font-weight: 400 !important;
            letter-spacing: 0.12em !important;
            color: rgba($color: #ffffff, $alpha: 0.51) !important;
        }
        a {
             font-size: 13px !important;
             font-weight: 400 !important;
             letter-spacing: 0.12em !important;
             color: rgba($color: #ffffff, $alpha: 0.51) !important;

             &:hover {
                color: rgba($color: #ffffff, $alpha: 1.0) !important;
             }
        }
        span {
            font-size: 23px !important;
        }
    }
    .mod {
        float: right;

        h4 {
            font-size: 15px !important;
            letter-spacing: 0.12em;
            margin-bottom: 16px;
        }
        .addr {
            color: rgba($color: #ffffff, $alpha: 0.83) !important;
        }
        .addr:hover {
            color: rgba($color: #ffffff, $alpha: 1.0) !important;
        }
        .fa-instagram {
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        }
        .fa-youtube{
            background-color: #FF0000;
        }
        .fa-facebook-f {
            background-color: #395A9A;
        }
        .fa-twitter {
            background-color: #55ADEE;
        }
        .fa-google {
            background-color: #D6492F;
        }
        .fa-yelp {
            background-color: #BF261A;
        }
        .fa-linkedin-in {
            background-color: #007AB9;
        }
        .h6 {
            color: rgba(255,255,255,.51)!important;
            letter-spacing: 0.12em !important;

            a {
                color: rgba(255,255,255,.51)!important;

                &:hover {
                    color: rgba(255,255,255,1.0)!important;
                }
            }
        }
    }
}
.foot .mod .fa-facebook-f, .foot .mod .fa-google, .foot .mod .fa-instagram, .foot .mod .fa-linkedin-in, .foot .mod .fa-twitter, .foot .mod .fa-yelp, .foot .mod .fa-youtube {
    height: 40px;
    width: 40px;
    padding: 13px 0px;
    border-radius: 50px;
}
.foot .mod .fa-facebook-f:hover, .foot .mod .fa-google:hover, .foot .mod .fa-instagram:hover, .foot .mod .fa-linkedin-in:hover, .foot .mod .fa-twitter:hover, .foot .mod .fa-yelp:hover, .foot .mod .fa-youtube:hover {
    opacity: 0.8;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}
.bg-black {
    background-color: #101215 !important;
}
@media (max-width: 1700px) {
    section.testimonials .title {
        right: 61px;
    }
}
@media (max-width: 1599px) {
    section.contact-form .wrap {
        margin: 0 0 0 -70px;
    }
    section.contact-form .at {
        right: 53%;
        top: 13%;
    }
    .owl-carousel .owl-nav {
        left: -332px;
    }
    section.testimonials-section.testimonials .testimonial-wrapper {
        margin-bottom: 45px !important;
        right: -25% !important;
    }
}
@media (max-width: 1500px) {
    #desktopMenu>li>a {
        margin: 0 10px !important;
    }
    .navbar .mod3 a.bg-primary span {
        display: none;
    }
    .navbar .mod3 a.bg-primary {
        min-width: 227px;
        padding: 38px;
    }
    section.contact-form .at {
        right: 54%;
        top: 6%;
    }
    section.testimonials .title {
        right: 135px;
    }
    section.testimonials-section.testimonials .testimonial-wrapper {
        right: -19% !important;
    }
    .testimonials-section .quotes {
        right: 56%;
    }
    .owl-carousel .owl-nav {
        left: -318px;
    }
}
@media (max-width: 1300px) {
    section.contact-form .wrap {
        padding: 50px;
        width: 100%;
        margin: 35px 0 0 0;
    }
    section.contact-form .at {
        right: 54%;
    }
    section.testimonials .title {
        margin-bottom: 30px !important;
    }
    section.testimonials-section.testimonials blockquote {
        padding: 40px !important;
    }
    section.testimonials .owl-carousel .owl-nav {
        left: -289px;
    }
}
@media (max-width: 1200px) {
    section.contact-form .wrap .title {
        font-size: 23px;
    }
    section.contact-form .at {
        right: 52%;
    }
    section.contact-form .wrap {
        padding: 45px;
        margin: 0 0 0 -130px;
    }
    section.contact-form .qc-form-wrapper {
        padding: 76px 195px 50px 0 !important;
    }
    section.testimonials .owl-carousel .owl-nav {
        left: -242px;
    }
    section.testimonials .title {
        font-size: calc(1.40625rem + 1.875vw);
    }
    .tagline-section .border-md {
        height: 285px;
        margin: 0 42px 0 0;
    }
    .testimonials-section .quotes {
        right: 52%;
        top: 10%;
    }
    header.header-section .navbar .logo {
        min-width: auto !important;
        padding: 10px 20px !important;
    }
}
@media (max-width: 991px) {
    header.header-section .navbar .border-right {
        display: none;
    }
    body.interior section[id*=interiorBanner] {
        margin: 160px 0 0!important;
    }
    section.tagline-section .office-headers a, section.tagline-section .office-headers span {
        margin: 0 10px !important;
    }
    section.services-section .service-headers {
        margin-right: 0 !important;
    }
    section.contact-form .at {
        right: auto;
        top: auto;
        bottom: auto;
        left: 35px;
    }
    section.contact-form .wrap {
        padding: 50px !important;
        width: 100% !important;
        margin: 25px auto 0;
    }
    section.contact-form .contact-img {
        width: 100%;
    }
    section.testimonials-section.testimonials .testimonials-img {
        width: 100%;
    }
    section.testimonials-section.testimonials blockquote {
        margin: 50px auto 0;
        width: 100%;
        padding: 40px !important;
    }
    section.testimonials .title {
        margin-bottom: 50px !important;
    }
    .testimonials-section .quotes {
        width: 210px;
        left: 0;
        float: right;
    }
    section.testimonials .owl-carousel .owl-nav {
        left: 105px;
        margin: 0;
        top: 0;
        right: auto;
        bottom: auto;
    }
    section.services-section .service-descriptions div[data-service-description-num] p {
        margin-top: 40px;
    }
    .foot .mod {
        float: none;
        margin-top: 35px;
    }
    section.contact-form .wrap .title {
        font-size: 20px;
        line-height: 31px;
    }
    section.testimonials-section .panel-image {
        padding: 65px 50px 25px;
    }
    section.testimonials-section.testimonials .testimonial-wrapper {
        right: 0 !important;
        padding: 75px 0 25px 0 !important;
    }
    section.testimonials .title {
        right: 0;
        top: 0;
    }
    .tagline-section .border-md {
        display: none;
    }
}
@media (max-width: 768px) {
    .owl-carousel .owl-nav {
        top: -205%;
    }
    section.contact-form .at {
        width: 125px;
    }
    section.testimonials-section.testimonials blockquote {
        min-height: auto !important;
        width: 100%;
    }
    section.faqs-section .display-3 {
        line-height: 40px;
    }
    section.contact-form .wrap {
        width: 100% !important;
        margin: 35px auto 0;
    }
    section.testimonials-section .panel-image {
        padding: 65px 30px 25px;
    }
    .foot .mod .fa-facebook-f, .foot .mod .fa-google, .foot .mod .fa-instagram, .foot .mod .fa-linkedin-in, .foot .mod .fa-twitter, .foot .mod .fa-yelp, .foot .mod .fa-youtube {
        margin-bottom: 5px;
    }
    section.testimonials .title {
        line-height: 40px;
    }
    section.faqs-section .display-3 {
        line-height: 40px !important;
    }
    section.testimonials-section.testimonials .testimonial-wrapper {
        right: 0!important;
    }
}
@media (max-width: 600px) {
    section.contact-form .at {
        left: 15px;
    }
    section.contact-form .wrap {
        padding: 50px 15px !important;
    }
    .owl-carousel .owl-nav {
        top: -169%;
    }
}
@media (max-width: 375px) {
    .owl-carousel .owl-nav {
        top: -165%;
    }
}
@media (max-width: 320px) {
    section.hero-section, section.hero-section .panel-image {
        min-height: 300px !important;
        height: 300px !important;
    }

    .owl-carousel .owl-nav {
        top: -155%;
    }
}
@media screen and (min-width: 992px) {
    ul#desktopMenu {
        > li {
            > a {
                position: relative;
                &.current,
                &.highlighted,
                &:hover {
                    &:before {
                        background: #D58F1F !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
            > a.has-submenu {
                position: relative;
                &.highlighted,
                &:hover {
                    &:before {
                        background: #D58F1F !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px !important;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
        }
    }
}


footer {
    .mod[style*="max-width:400px"] {
        @media screen and (max-width: 991px) {
            max-width: 100% !important;
        }
    }
}